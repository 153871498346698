import React from 'react'
import CommonSection from '../components/UI/CommonSection'
import Helmet from '../components/Helmet/Helmet'
import AboutSection from '../components/UI/AboutSection'
import { Container, Row, Col } from 'reactstrap'

import driverImg from '../assets/all-images/driver.jpg'
import '../styles/about.css'

const AboutUs = () => {
    return <Helmet title='About'>
        <CommonSection title='About Us'/>
        <AboutSection aboutClass="aboutPage"/>
        <section className="about__page-section">
            <Container>
                <Row>
                    <Col lg='6' md='6' sm='12'>
                    <div className="about__page-img">
                        <img src={driverImg} alt="" className='w-100 rounded-3'/>
                    </div>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                    <div className="about__page-content">
                        <h2 className="section__title">
                            Budget Car Rental in Singapore
                        </h2>
                        <p className="section__description">
                        Here at Dream Car Leasing, we provide the #1 cheapest car rental service in Singapore.

                        Dream Car Leasing started out as a modest company of employees. Over the years, we have expanded and upgraded our operations to provide customers with the best budget car rental options. Today, we are proud to showcase our wide range of car models and services which will satisfy customer needs and requirements.

                        Dream Car  Leasing’s purpose is to provide affordable car rental and leasing services in Singapore. We believe that every person should get the opportunity to drive a car in Singapore whenever they want without having to worry about the ridiculous costs that go behind owning a private vehicle. 
                        </p>
                        <div className='d-flex align-items-center gap-3 mt-4'>
                            <span className='fs-4'><i className='ri-phone-line'></i></span>
                            <div>
                                <h6 className="section__subtitle">Need Any Help?</h6>
                                <h4>+65 6748 9747, +65 8876 8853</h4>
                            </div>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </Helmet>
}

export default AboutUs