import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem, Card, CardBody } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import '../styles/contactus.css'

const contactInfo = [
    {
        icon: '<i className="ri-home-7-fill"></i>',
        display: 'Dream Car Leasing Pte. Ltd.'
    },
    {
        icon: '<i className="ri-map-pin-fill"></i>',
        display: "155 Kaki Bukit Ave 1, Level 2 Shun Li Industrial Park, <br> Singapore 416012"
    },
    {
        icon: '<i className="ri-phone-line"></i>',
        display: '+65 6748 9747, +65 8876 8853'
    },
    {
        icon: '<i className="ri-mail-line"></i>',
        display: 'info@dreamcarrental.xyz'
    },
]

const ContactUs = () => {
    return <Helmet title="Contact Us">
        <CommonSection title="Contact Us"/>
        <section className='p-0'>
            <div className='contact__top'>
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <h3>Contact Us</h3>
                            <p>
                            We'd love to show you how you can get more traffic and leads, increase your sales productivity, provide better customer service, or all of the above! Here are a few ways to reach out to our sales team.
                            </p>
                        </Col>
                    </Row>
                    <Row className='justify-content-md-center contact__number mt-5 mb-5'>
                        <Col lg="4" md="5" sm="12" className='mt-3'>
                            <Card>
                                <CardBody>
                                    <div className='text-center'><i className="ri-phone-line"></i></div>
                                    <p className='text-center'>Call Us Directly</p>
                                    <p className='text-center'>+65 6748 9747, +65 8876 8853</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="5" sm="12" className='mt-3'>
                            <Card>
                                <CardBody>
                                    <div className='text-center'><i className="ri-mail-line"></i></div>
                                    <p className='text-center'>Email Us</p>
                                    <p className='text-center'>info@dreamcarrental.xyz</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='d-flex contact__middle'>
                <div className="contact__map">
                    <iframe
                        width="100%"
                        height="100%"
                        allowFullScreen=""
                        loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.927823686863!2d103.9118!3d1.337084!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17e31f0d8ae3%3A0xeb7c0a80d0cfd939!2sDREAM%20CAR%20LEASING%20PTE.%20LTD.!5e0!3m2!1sen!2sus!4v1722565342878!5m2!1sen!2sus"
                    ></iframe>
                </div>
                <div className="contact__info">
                    <h3 className='fw-bold mb-3'>Contact Information</h3>
                        {
                            contactInfo.map((item, index) => (
                                <ListGroupItem key={index} className="p-0 mt-3 quick__link d-flex align-items-center gap-2">
                                    <span style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: item.icon }} /> 
                                    <span style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: item.display }} />
                                </ListGroupItem>
                            ))
                        }
                </div>
            </div>
        </section>
    </Helmet>
}

export default ContactUs